import React from 'react'
import './styles.css';

const Messenger = () => {
    
    return (
        <div className='messenger'>
            <a href='https://www.facebook.com/messages/t/vietaipublic'>
                <img alt='' src='./images/messenger-logo.png'/>
            </a>
        </div>
    )
}

export default Messenger